<template>
    <v-container style="margin: 0; padding: 0">
        <audio id="clickSound">
            <source  src="../assets/audio/click2.mp3" type="audio/mpeg">
        </audio>
        <!-- <v-layout text-center style=" justify-content: center">
            <v-flex xs12 style=" ">
                <v-img :src="require('../assets/logo-main.png')" style="height: 40px; width: 200px"></v-img>            
            </v-flex>           
        </v-layout> -->

        <div style="display: flex; width: 100%; justify-content: center; margin-top: 5pt">
            <img :src="require('../assets/logo-main.png')" style="height: 40px !important; width: 200px">
        </div>

        <div style="position: fixed; top: 5pt; right: 5pt">
            <v-btn icon @click="toggleSound">
                <v-icon>{{ allowSound ? 'volume_up' : 'volume_off' }}</v-icon>
            </v-btn>
        </div>

        <div style="position: fixed; top: 5pt; left: 5pt">
            <v-btn icon @click="showControlMenu = true">
                <v-icon>more_horiz</v-icon>
            </v-btn>
        </div>
        <div>{{  activeGame.GameId  }}</div>


        <div class="score-table-container" v-if="activeGame != null">
            <div class="score-table" >
                <div @click="directToggle(0)" class="score-table-col" :class="(activeGame.CurrentVisitPlayer == 0) ? 'score-table-col-active' : ''">
                    <div class="score-header">{{ activeGame.Players[0].Name }}</div>
                    <div>{{ activeGame.Players[0].Score }}</div>
                    <div>{{ activeGame.Players[0].Break }}</div>
                    <div>{{ activeGame.Players[0].Wins }}</div>
                    <div>{{ playerAhead(0) }}</div>
                </div>
                <div class="score-table-col">
                    <div>&nbsp;</div>
                    <div>ניקוד</div>
                    <div>ברייק</div>
                    <div>נצחונות</div>
                    <div>הובלה</div>
                </div>

                <div @click="directToggle(1)" class="score-table-col" :class="(activeGame.CurrentVisitPlayer == 1) ? 'score-table-col-active' : ''">
                    <div class="score-header">{{ activeGame.Players[1].Name }}</div>
                    <div>{{ activeGame.Players[1].Score }}</div>
                    <div>{{ activeGame.Players[1].Break }}</div>
                    <div>{{ activeGame.Players[1].Wins }}</div>
                    <div>{{ playerAhead(1) }}</div>
                </div>
            </div>
        </div>
        <div v-if="activeGame != null" style="width: 100%; text-align: center">
            נקודות על השולחן {{ remainingPointsOnTable }}
        </div>            



        <!-- <transition name="fade">
            <p v-if="show">hello</p>
        </transition> -->

        
        <!-- <v-toolbar>
            <v-toolbar-side-icon  @click="showControlMenu = true" ></v-toolbar-side-icon>
            <v-layout row fluid>
                <v-flex grow pa-1>
                    <v-img :src="require('../assets/logo-main.png')" class="my-3"></v-img>                     
                </v-flex>
                <v-flex shrink pa-1>
                    <v-btn icon dark @click="showControlMenu = false">
                        <v-icon>close</v-icon>
                    </v-btn>                
                </v-flex>
            </v-layout>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">

            </v-toolbar-items>
        </v-toolbar> -->

        <!-- <v-toolbar>
            <v-toolbar-side-icon  @click="showControlMenu = true" ></v-toolbar-side-icon>
            <v-toolbar-title >
                <v-img :src="require('../assets/logo-main.png')" style="height: 40px; width: 200px"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleSound">
                <v-icon>{{ allowSound ? 'volume_up' : 'volume_off' }}</v-icon>
            </v-btn>
        </v-toolbar> -->

        <v-dialog v-model="showHistory" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="activeGame != null">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="showHistory = false">
                   <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>היסטוריית משחק</v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
                <v-container class="history-title">
                    <v-layout style="padding: 5pt">
                        <v-flex subheading xs6 text-center>{{ activeGame.Players[0].Name }}</v-flex>
                        <v-flex subheading xs6 text-center>{{ activeGame.Players[1].Name }}</v-flex>
                    </v-layout>
                </v-container>
                <v-container style="width: 100%;">
                    <v-timeline  clipped>
                        <v-timeline-item
                        v-for="entry in history"
                        :key="entry.Id"
                        class="mb-3"
                        :color="scoreColor[entry.Score]"
                        small
                        :left="entry.PlayerName == activeGame.Players[0].Name"
                        :right="entry.PlayerName == activeGame.Players[1].Name"
                        >
                        <template v-slot:icon>
                            <img width="64" :src="require(`../assets/images/balls-clean/${balls[entry.Score]}`)">
                        </template>

                        <v-layout justify-space-between row wrap >
                            <v-flex xs12 v-text="entry.TimeString" font-weight-black></v-flex>
                            <v-flex xs12 font-weight-thin v-html="getHistoryEntryText(entry)"></v-flex>
                        </v-layout>
                        </v-timeline-item>
                    </v-timeline>

                    <!-- </v-layout> -->
                </v-container>


            </v-card>
        </v-dialog>

        <v-dialog v-model="showVAR" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="changeLiveFeedVideoMode(false)">
                   <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Video Assistant Referee</v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
                <v-container style="width: 100%">
                    <v-layout
                        v-touch="{
                        left: () => swipe('left'),
                        right: () => swipe('right')
                        }"
                        column
                        align-center
                        justify-center
                        class="grey lighten-2 swipe-area" >
                    </v-layout>

                    <!-- <v-layout v-layout align-center justify-center row fill-height> -->
                        <v-flex xs12 center>
                            <v-slider label="תמונה #:" style="width: 95% !important; flex: flex-grow" thumb-label="always" v-model="overlayImageIndex" :min="0" :max="overlayImageCount" :value="overlayImageIndex" @input="changeOverlayImage" ></v-slider>                        
                        </v-flex>

                        <v-flex xs12 center>
                            <v-slider label="שקיפות:" style="width: 95% !important; flex: flex-grow" thumb-label="always" v-model="imageOpacity" :min="0.1" :max="0.9" :step="0.1" :value="imageOpacity" @input="changeOverlayOpacity" ></v-slider>
                        </v-flex>
                        <v-flex xs12 text-center >
                            <v-btn rounded color="info" @click="showLiveFeedFunc">{{ showLiveFeed ? 'הפסק' : 'הפעל'}} שקיפות</v-btn>                            
                        </v-flex>

                    <!-- </v-layout> -->
                </v-container>


            </v-card>
        </v-dialog>

        <v-dialog v-model="showControlMenu" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showControlMenu = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>אפשרויות נוספות</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-toolbar-items>
                        <v-btn dark flat @click="showControlMenu = false">Save</v-btn>
                    </v-toolbar-items> -->
                </v-toolbar>
                <v-list>
                    <v-list-tile>
                        <v-list-tile-action>
                        <v-switch v-model="preventDisplaySleep" color="purple"></v-switch>
                        </v-list-tile-action>
                        <v-list-tile-title>מנע כיבוי מסך</v-list-tile-title>
                    </v-list-tile>


                    <!-- <v-list-tile>
                        <v-layout text-center>
                            <v-flex xs12>
                                <v-btn color="primary" round  @click="goToMainMenu" >Main menu (temp)</v-btn>                                
                            </v-flex>
                        </v-layout>
                    </v-list-tile> -->

                </v-list>                
            </v-card>
        </v-dialog>        

        
        <v-layout text-center row wrap v-if="!isAnonymous && activeGame == null" style="margin-top: 50pt">
            <v-flex xs12 headline>
                לא נמצא משחק פעיל
            </v-flex>            
            <v-flex xs12 style="margin-top: 50pt">
                <v-btn color="success" @click="goToNewGame()">התחל משחק חדש</v-btn>
            </v-flex>
            <v-flex xs12>
                או
            </v-flex>              
            <v-flex xs12>
                <v-btn color="success" @click="goToMainMenu()">חזור לתפריט הראשי</v-btn>
            </v-flex>               
        </v-layout>

        <v-layout text-center row wrap v-if="isAnonymous && activeGame == null" style="margin-top: 50pt">
            <v-flex xs12 headline>
                המשחק הסתיים!<br>
                בקרוב תקבל SMS עם הוידיאו והיסטוריית המשחק
            </v-flex>            
            <v-flex xs12 style="margin-top: 50pt">
                <v-btn color="success" @click="goToNewGame()">התחל משחק חדש</v-btn>
            </v-flex>
        </v-layout>

        



        <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0" v-if="activeGame != null"  >

        <!-- <v-container fluid>
            <v-layout row>
                <v-flex xs1 pa-1 subheading text-center>
                </v-flex>
                <v-flex grow pa-1 subheading></v-flex>
                <v-flex xs2 subheading pa-1 text-xs-right >Wins</v-flex>
                <v-flex xs2 subheading pa-1 text-xs-right >Break</v-flex>
                <v-flex xs2 subheading pa-1 text-xs-right>Score</v-flex>
            </v-layout>        
            
            <v-layout v-for="(player, n) in activeGame.Players" :key=n  @click="directToggle(n)" row :style="{color: (activeGame.CurrentVisitPlayer == n) ? 'yellow' : 'white'}">
                <v-flex xs1 headline style="display: flex; justify-content: flex-start ">
                    <v-icon :color="(activeGame.CurrentVisitPlayer == n) ? 'yellow' : 'white'" v-show="(activeGame.CurrentVisitPlayer == n)">play_arrow</v-icon>
                </v-flex>
                <v-flex grow pa-1 headline>{{ formattedPlayerName(player.Name) }}</v-flex>
                <v-flex xs2 headline pa-1 text-xs-right >{{ player.Wins }}</v-flex>
                <v-flex xs2 headline pa-1 text-xs-right >{{ player.Break }}</v-flex>
                <v-flex xs2 headline pa-1 text-xs-right>{{ player.Score }}</v-flex>
            </v-layout>                
        </v-container> -->

        <!-- <v-layout text-center style="margin-top: 5pt"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs4>
                <v-btn :color="isFoul ? 'error' : 'primary'" round @click="toggleFoul" >Foul</v-btn>
            </v-flex>
            <v-flex xs4>
                <v-btn :color="isRedFoul ? 'error' : 'primary'" round  @click="toggleRedFoul" >Foul w/ red</v-btn>
            </v-flex>
            <v-flex xs4>
                <v-btn :color="isFreeball ? 'success' : 'primary'" round @click="toggleFreeball" >Free ball</v-btn>
            </v-flex>
        </v-layout>        
 -->
        <div class="score-table" v-if="activeGame.FrameStartTime > 0">
            <div class="score-table-col">
                <div style="display: flex; width: 100%; justify-content: center;  align-items: center; align-content: center; flex-flow: row wrap;">
                    <img @click="setScore(1)" :src="require(`../assets/images/balls/red${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                    <div style="width: 10%"></div>
                    <img @click="setScore(2)" :src="require(`../assets/images/balls/yellow${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                    <div style="width: 10%"></div>
                    <img @click="setScore(3)" :src="require(`../assets/images/balls/green${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                    <div style="width: 10%"></div>
                    <img @click="setScore(4)" :src="require(`../assets/images/balls/brown${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                </div>
                <div style="display: flex; width: 100%; justify-content: center;  align-items: center; align-content: center; flex-flow: row wrap;">
                    <img @click="setScore(5)" :src="require(`../assets/images/balls/blue${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                    <div style="width: 10%"></div>                    
                    <img @click="setScore(6)" :src="require(`../assets/images/balls/pink${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                    <div style="width: 10%"></div>                    
                    <img @click="setScore(7)" :src="require(`../assets/images/balls/black${isGlowing ? '-glow' : ''}.svg`)"  width="15%">
                </div>                        
            </div>
        </div>
        <!-- <v-layout text-center wrap  style="margin-top: 5pt" v-if="activeGame.FrameStartTime > 0">
            <v-flex xs12 >
                <v-layout text-center wrap justify-center> 
                    <v-flex  justify-center v-for="(ball, index) in balls" :key="index" xs3   @click="setScore(index + 1)"  style=" display: flex; align-items: center">
                        <div class="score-ball-icon justify-center text-center" v-bind:style="{ backgroundColor: ball, marginTop: '20pt', 'box-shadow': isFoul || isRedFoul ? '0px 0px 15px red' : 'none'  }">
                            <img :src="require(`../assets/images/balls/${ball}`)"  width="100">
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>         -->

        <!-- <v-layout text-center wrap  style="margin-top: 5pt" v-if="activeGame.FrameStartTime > 0">
            <v-flex xs12 >
                <v-layout text-center wrap justify-center> 
                    <v-flex  justify-center v-for="(ball, index) in balls" :key="index" xs3   @click="setScore(index + 1)"  style=" display: flex; align-items: center">
                        <div class="score-ball-icon justify-center text-center" v-bind:style="{ backgroundColor: ball, marginTop: '20pt', 'box-shadow': isFoul || isRedFoul ? '0px 0px 15px red' : 'none'  }">
                            <span>{{ index +1 }}</span>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout> -->

        <v-layout text-center style="margin-top: 10pt"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs4>
                <v-btn color="primary" rounded @click="undo()" >ביטול</v-btn>
            </v-flex>
            <v-flex xs4>
                <!--  <v-btn color="primary" round @click="doReplay()" >Replay</v-btn>  -->
            </v-flex>
            <v-flex xs4>
                <v-btn :disabled="activeGame.TableId == ''" color="primary" rounded @click.native="changeLiveFeedVideoMode(true)" dark>VAR</v-btn>
            </v-flex>

            <!-- <v-flex xs6>
                <v-btn :color="showAfterFoul ? 'warning' : 'primary'" round @click="showAfterFoul = !showAfterFoul" >After Foul</v-btn>
            </v-flex> -->
            <!-- <v-flex xs12 v-if="activeGame.TableId != ''">
                <v-btn color="primary" round @click.native="changeLiveFeedVideoMode(true)" dark>VAR</v-btn>
            </v-flex> -->
        </v-layout>

 
        <v-layout text-center row wrap id="foul-panel" ref="foul-panel" style="margin-top: 10pt; background-color: rgba(255, 255, 255, 0.2); border-radius: 15px;"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs4>
                <v-btn small :color="isFoul ? 'error' : 'primary'" rounded @click="toggleFoul(); showAfterFoul = !showAfterFoul" >עבירה</v-btn>
            </v-flex>
            <v-flex xs4>
                <v-btn small :color="isRedFoul ? 'error' : 'primary'" rounded  @click="toggleRedFoul(); showAfterFoul = !showAfterFoul" >עבירה עם אדום</v-btn>
            </v-flex>
            <v-flex xs4>
                <v-btn small :color="isFreeball ? 'success' : 'primary'" rounded @click="toggleFreeball(); showAfterFoul = !showAfterFoul" >כדור חופשי</v-btn>
            </v-flex>
            <transition name="expand">
                <v-flex xs12 style="display: flex; width: 100%; align-items: flex-start; align-content: flex-start; font-size: 10pt; padding: 5pt" v-show="isGlowing">
                        <v-layout text-xs-right row wrap>
                        <v-flex xs12>
                            <b style="color: #ffc600">עבירה</b> - סימון עבירה מעניק ליריב ניקוד לפי הכדור המעורב בעבירה (מינימום 4 נקודות) 
                        </v-flex>
                        <v-flex xs12>
                            <b style="color: #ffc600">עבירה עם אדום</b> - סימון עבירה שבמהלכה נכנס כדור אדום. אפשרות זו דומה לעבירה רגיל אך בנוסף תעדכן את הניקוד על השולחן
                        </v-flex>
                        <v-flex xs12>
                            <b style="color: #ffc600">כדור חופשי</b> - סימון הכדור הנכנס ככדור חופשי
                        </v-flex>
                        </v-layout>
                </v-flex>
            </transition>
        </v-layout>        

        <!-- <v-layout text-center wrap style="margin-top: 0pt" v-if="true">
            <v-flex xs12 >
                <toggle-button :value="scoreMode" @change="scoreMode = !scoreMode" :sync="false" :font-size="15" :width="150" :height="20" :color="{checked: '#f95a5a', unchecked: '#17bf43'}" :labels="{checked: 'After Foul', unchecked: 'Normal Score'}"/>
            </v-flex>
        </v-layout>         -->


        <v-layout text-center style="margin-top: 10pt" v-if="activeGame.FrameStartTime > 0">
            <v-flex xs12>
                <v-btn color="primary" rounded @click="showHistoryFunc()" >היסטוריה</v-btn>
            </v-flex>
        </v-layout>

        <v-layout text-center style="margin-top: 10pt"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs6>
                <v-btn color="primary" rounded @click="(activeGame.FrameStartTime > 0) ? endFrame(true) : startFrame()" >{{ activeGame.FrameStartTime > 0 ? "סיים פריים" : "התחל פריים" }}</v-btn>
            </v-flex>
            <v-flex xs6>
                <v-btn color="primary" rounded @click="reRack(true)" >Re-Rack</v-btn>
            </v-flex>
        </v-layout>

        <v-layout text-center subheading style="margin-top: 15pt"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs6>זמן פריים</v-flex>
            <v-flex xs6>זמן משחק</v-flex>
        </v-layout>
        <v-layout text-center subheading style="margin-top: 0pt"  v-if="activeGame.FrameStartTime > 0">
            <v-flex xs6>{{ frameTime }}</v-flex>
            <v-flex xs6>{{ matchTime }}</v-flex>
        </v-layout>

        <v-layout text-center row wrap  v-if="activeGame.FrameStartTime < 0" style="margin-top: 20pt">
            <v-flex xs12>
                <v-btn color="primary" rounded @click="startFrame()" >התחל את פריים {{ activeGame.PlayedFrames + 1 }} </v-btn>
            </v-flex>
            <v-flex xs12 style="margin-top: 20pt">
                <v-btn color="error" rounded @click="endGame" >סיים משחק</v-btn>
            </v-flex>
             
        </v-layout>


        </v-container>
    </v-container>
</template>

<script>
/* eslint-disable */
import { eventBus} from '../main';
import { mapGetters } from 'vuex';
import NoSleep from 'nosleep.js'
import { POINT_CONVERSION_COMPRESSED } from 'constants';
import { setInterval } from 'timers';
//import VueCookies from 'vue-cookies'

try
{
    navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
}
catch (err)
{
    console.log("Vibrate does not suppored!");
    console.log(err);
    navigator.vibrate = null;
}


var moment = require('moment');

export default {
    data() {
        return {
            //internalGameIndex: this.$route.params.gameId,
            showAfterFoul: false,
            isFoul: false,
            isRedFoul: false,
            isFreeball: false,
            showVAR: false,
            overlayImageIndex: this.$store.getters.overlayImageCount,
            swipeDirection: "",
            swipeCount: 0,
            showLiveFeed: false,
            imageOpacity: 0.6,
            visitingPlayer: 1,
            score: [10, 147],
            wins: [1, 0],
            break: [147, 0],
            scoreColor: ["#ffffff", "#d70002", "#fad743", "#048301", "#b65e05", "#0a59ed", "#ff99b0", "#313131"],
            balls: ["white.png", "red.png", "yellow.png", "green.png", "brown.png", "blue.png", "pink.png", "black.png"],
            preventDisplaySleep: false,
            noSleepControl: new NoSleep(),
            showControlMenu: false,
            timer: null,
            frameTime: "00:00:00",
            matchTime: "00:00:00",
            scoreMode: true,
            showHistory: false,
            history: []

        };
    },
    mounted() {
        this.$vuetify.rtl = true;
        // this.$nextTick(function () {
        // });
         
        
        // var self = this;
        // setTimeout(function() {
        //     self.initHeight();
        // }, 300);
        
    },
    created () {
        console.log(`query: ${this.$route.query.gameid}`);
        if (this.$route.query.gameid)
            this.$store.dispatch("requestGameInfo", this.$route.query.gameid);
        else
            this.$store.dispatch("requestGameInfo");

        var self = this;
        eventBus.$on('onFoundGame', () => {
            if (self.activeGame == null)
                return;

            self.$connect();
            var gameMeta = $cookies.get(self.activeGame.GameId);
            var timeDiff = 0;    

            if (self.timer == null)
            {
                self.timer = setInterval(() => {

                    if (self.activeGame != undefined)
                    {
                       // console.log(`Now: ${Date.now()}, Frame ${self.activeGame.FrameStartTime} Diff: ${Date.now() - self.activeGame.FrameStartTime}`);
                        
                        if ( Date.now() - self.activeGame.FrameStartTime < 0 && timeDiff == 0)
                        {
                            if (gameMeta != null && gameMeta != undefined)
                            {
                                if (gameMeta.Diff != undefined)
                                {
                                    timeDiff = gameMeta.Diff;
                                }
                            }
                            else
                            {
                                timeDiff =  Math.abs((Date.now() - self.activeGame.FrameStartTime));
                                $cookies.set(self.activeGame.GameId, {Diff: timeDiff }, 60 * 60 * 24);
                            }
                        }

                        if (self.activeGame.FrameStartTime > 0)
                            self.frameTime = self.millisToTime(Date.now() - self.activeGame.FrameStartTime + timeDiff);
                        else
                            self.frameTime = "00:00:00";
                        
                        if (self.activeGame.MatchStartTime > 0)
                            self.matchTime = self.millisToTime(Date.now() - self.activeGame.MatchStartTime + timeDiff);
                        else
                            self.matchTime = "00:00:00";

                    }
                }, 1000);
            }            
        });
    },
    beforeDestroy () {
        if (this.timer != null)
            clearInterval(this.timer._id);

        this.$disconnect();            
    },
    watch:
    {
        overlayImageCount: function (newVal, oldVal) 
        {
            if (newVal != oldVal)
                this.overlayImageIndex = newVal;
        },

        preventDisplaySleep: function (newVal, oldVal) 
        {
            if (newVal)
            {
                this.noSleepControl.enable();
            }
            else
            {
                this.noSleepControl.disable();
            }
            
        }
    },
    computed: {
        ...mapGetters({
            activeGame: "getActiveGame",
            overlayImageCount: "getOverlayImageCount",
            allowSound: "getAllowSound",
            currentDomain: "getCurrentDomain",
            isAnonymous: "getIsAnonymous"
        }),
        isGlowing() {
            return this.isFoul || this.isRedFoul || this.isFreeball;
        },
        remainingPointsOnTable()
        {
            if (this.activeGame != null)
                return this.activeGame.RemainingPointsOnTable;
            else 
                return 0;
        }
    },
    methods: {
        getHistoryEntryText(entry)
        {
            var str = "";
            var score = entry.Score;
            if (entry.Foul == 1 || entry.FoulWithRed == 1)
            {
                str = "<span style='font-weight: bold; color: #FF9800'>עבירה</span> של ";
                score = Math.max(4, score);
            }
            else
            {
                str = "";
            }
            str += score + " נקודות";
            if (entry.FoulWithRed)
                str += " עם כדור אדום שנכנס";

            if (entry.Freeball)
                str += "<span style='font-weight: bold; color: #0dff4a'> ככדור חופשי</span>";

            str += "<br>ניקוד כולל: " + entry.TotalScore;
            str += "<br>ברייק: " + entry.Break;

            return str;
            
        },
        playerAhead(idx)
        {
            if (this.activeGame == null)
                return "0";

            var opponent = (idx == 0) ? 1 : 0;
            if (this.activeGame.Players[idx].Score > this.activeGame.Players[opponent].Score)
                return this.activeGame.Players[idx].Score - this.activeGame.Players[opponent].Score
            
            return "0";
        },
        reRack(ask) {
            if (ask)
            {
                this.$store.dispatch("showMessageDialog", {
                    Title: "התחלה מחדש",
                    Body: `אתה עומד להתחיל את פריים  ${this.activeGame.PlayedFrames + 1} מחדש.\nאתה בטוח?`,
                    Type: "warning",
                    Callback: this.reRack
                });
            }
            else
            {
                this.$store.dispatch("reRack", this.activeGame.GameId);
            }
        },
        showHistoryFunc() {
            var self = this;
            this.history = [];
            console.log(`${self.currentDomain}/get-game-entries`);
            this.$http.get(`${self.currentDomain}/get-game-entries`,
            {
                params: { gameId: self.activeGame.GameId}
            }, 
            { headers: { 'content-type': 'application/json' } })
            .then(
                response => {
                    if (response.status == 200) {
                        if (response.body.Entries[self.activeGame.PlayedFrames] != undefined)
                        {
                            for (var entryIdx in response.body.Entries[self.activeGame.PlayedFrames].Entries)
                            {
                                const entry = response.body.Entries[self.activeGame.PlayedFrames].Entries[entryIdx];
                                const time = (entry.Timestamp - response.body.Entries[self.activeGame.PlayedFrames].StartDate);
                                self.history.push({ Id: entryIdx, 
                                                    Time: time,
                                                    TimeString: self.millisToTime(time),
                                                    Score: entry.Score, 
                                                    PlayerName: response.body.Players[entry.PlayerId].Name,
                                                    Foul: entry.Foul,
                                                    FoulWithRed: entry.FoulWithRed,
                                                    Freeball: entry.Freeball,
                                                    TotalScore: entry.TotalScore,
                                                    Break: entry.Break });
                            }
                            console.log(self.history);
                            self.history.reverse();
                            // console.log(response.body);
                        }
                    }
                },
                response => {
                    console.error(response.body);
                }
            );               
            this.showHistory = true;
        },
        goToNewGame() {
            if (this.isAnonymous)
            {
                this.$router.push("newgamev2");
            }
            else
            {
                this.$router.push("newgame");
            }
            
        },
        goToMainMenu()
        {
            this.$router.push("mainmenu");
        },
        formattedPlayerName(text) {
            if (text.length > 12 && text.indexOf(' ') != -1)
            {
                var tmp = text.split(" ");
                var newName = tmp[0].charAt(0).toUpperCase() + ". " + tmp[1];
                return (newName > 12) ? tmp[1] : newName;
            }
            else 
                return text;
        },        
        showLiveFeedFunc(forceOff)
        {
            this.showLiveFeed = (typeof forceOff != 'undefined' && forceOff == true) ? false : !this.showLiveFeed;
            this.$store.dispatch("showOverlayLiveFeed", this.showLiveFeed);
        },
        doReplay()
        {
            this.$store.dispatch("requestReplay");
        },
        changeLiveFeedVideoMode(show) {
            console.log(show);
            this.$store.dispatch("toggleVAR", show);
            this.showVAR = show;
            if (show)
            {
                this.overlayImageIndex = this.overlayImageCount;
                var self = this;
                setTimeout(()=> {
                    self.$store.dispatch("showOverlayImage", self.overlayImageIndex);
                }, 200);
            }
            else
            {
                this.showLiveFeedFunc(true);
            }
        },
        swipe (direction) {
            if (direction == "left")
                this.overlayImageIndex = Math.max(0, this.overlayImageIndex - 1);
            else
                this.overlayImageIndex = Math.min(this.overlayImageCount, this.overlayImageIndex + 1);
            
            this.$store.dispatch("showOverlayImage", this.overlayImageIndex);
        },
        timestampToDate(timestamp) {
            return moment(parseInt(timestamp) * 1000).format("DD/MM/YYYY HH:mm");
        },
        millisToTime(ms) {
            return moment('2000-01-01 00:00:00').add(moment.duration(ms)).format('HH:mm:ss');            
        },        
        directToggle(player) {
            this.$store.dispatch("setVisitingPlayer", {gameId: this.activeGame.GameId, player: player });
            // for (var i = 0; i < this.players.length; i++)
            //     this.players[i].IsVisiting = false;
            
            // this.players[player].IsVisiting = !this.players[player].IsVisiting;            
        },
        toggleVisitPlayer() {
            this.$store.dispatch("toggleVisitPlayer", 0);
        },

        setScore(score) {
            if (this.allowSound)
                document.getElementById("clickSound").play();

            if (navigator.vibrate)
                navigator.vibrate(100);                

            // this.isGlowing = score;
            this.$store.dispatch("setScore", { GameId: this.activeGame.GameId, Player: this.activeGame.CurrentVisitPlayer, Score: score, IsFoul: this.isFoul, IsRedFoul: this.isRedFoul, IsFreeball: this.isFreeball });
            this.isFoul = false;
            this.isRedFoul = false;
            this.isFreeball = false;
            this.showAfterFoul = false;
            // setTimeout(()=> {
            //     this.isGlowing = 0
            // }, 1000);
        },
        // TODO: Use toggle component instead of manual manager:
        toggleFoul() {
            this.isFoul = !this.isFoul;
            if (this.isFoul)
            {
                this.isFreeball = false;
                this.isRedFoul = false;
            }
        },
        toggleFreeball() {
            this.isFreeball = !this.isFreeball;
            if (this.isFreeball)
            {
                this.isFoul = false;
                this.isRedFoul = false;
            }
        },
        toggleRedFoul() {
            this.isRedFoul = !this.isRedFoul;
            if (this.isRedFoul)
            {
                this.isFreeball = false;
                this.isFoul = false;
            }
        },
        undo() {
            if (this.allowSound)
                document.getElementById("clickSound").play();           
            
            this.$store.dispatch("undoLastScore", this.activeGame.GameId);
        },
        changeOverlayImage() {
            this.$store.dispatch("showOverlayImage", this.overlayImageIndex);
        },
        changeOverlayOpacity() {
            this.$store.dispatch("changeOverlayOpacity", this.imageOpacity);
        },

        toggleSound()
        {
            this.$store.commit("toggleAllowSound");
        },

        endFrame(ask)
        {
            if (ask)
            {
                this.$store.dispatch("showMessageDialog", {
                    Title: "סיום פריים",
                    Body: `אתה עומד לסיים את פריים ${this.activeGame.PlayedFrames + 1}.\nאתה בטוח?`,
                    Type: "warning",
                    Callback: this.endFrame
                });
            }
            else
            {
                this.$store.dispatch("endFrame", this.activeGame.GameId);
            }
        },

        startFrame()
        {
            this.$store.dispatch("startFrame", this.activeGame.GameId);
        },

        endGame(ask)
        {
            if (ask)
            {
                this.$store.dispatch("showMessageDialog", {
                    Title: "סיום משחק",
                    Body: `אתה עומד לסיים את המשחק.\nאתה בטוח?`,
                    Type: "warning",
                    Callback: this.endGame
                });
            }
            else
            {
                this.$store.dispatch("endGame", this.activeGame.GameId);
                this.$disconnect();
            }
        },
    }
}
</script>

<style>
.smooth-enter-active, .smooth-leave-active {
  transition: height .5s;
  overflow: hidden;
}
.smooth-enter, .smooth-leave-to {
  height: 0;
}
</style>