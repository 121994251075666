import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translation'
import { i18n } from '../i18n.js'

import Login from '../components/Login.vue'
import Register from '../components/Registration.vue'
import ResetPassword from '../components/ResetPassword.vue'
//import RegistrationConfirmation from '../components/RegistrationConfirmation.vue'

import Home from '../views/Home'
import Beta from '../views/Beta'
import ClubsBeta from '../views/Clubs_Beta'
/*
import MainMenu from '../components/MainMenu'
import ConnectToTable from '../components/ConnectToTable'
import GameController from '../components/GameController'
import UserAccount from '../components/UserAccount'
import NewGame from '../components/NewGame'
import NewGameV2 from '../components/NewGameV2'
import Stats from '../components/Stats'

import ScoreViewer from '../components/ScoreViewer'

import ClubManagement from '../components/ClubManagement'
*/
import AdminMain from '../views/AdminMain'
//import AdminUsers from '../components/AdminUsers'
//import AdminTables from '../components/AdminTables'


import MyAccount from '../components/MyAccount'
import CustomerRegistration from '../components/CustomerRegistration'
import PaymentSuccess from '../components/PaymentSuccess'
import PaymentCancel from '../components/PaymentCancel'
import GameController from '../components/GameController'


import ClubPurchasing from '../views/ClubPurchasing'
import Landing from '../views/Landing'



import { store } from '../store/index'


Vue.use(VueRouter)

const subRroutes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: 'beta',
        name: 'beta',
        component: Beta
    },
    {
        path: 'forms',
        name: 'forms-beta',
        component: ClubsBeta
    },
    {
        path: 'landing',
        name: 'landing',
        component: Landing
    },    
    {
        path: 'login',
        name: 'login',
        component: Login
    },
    {
        path: 'resetpassword',
        name: 'resetpassword',
        component: ResetPassword
    },
    {
        path: 'gamecontroller',
        name: 'gamecontroller',
        component: GameController
    },    
/*    
   {
        path: 'register',
        name: 'register',
        component: Register
    },
    {
        path: 'mainmenu',
        name: 'mainmenu',
        component: MainMenu
    },
    {
        path: 'connecttotable',
        name: 'connecttotable',
        component: ConnectToTable
    },
    {
        path: 'confirm',
        name: 'confirm',
        component: RegistrationConfirmation
    },
    {
        path: 'gamecontroller',
        name: 'gamecontroller',
        component: GameController
    },
    {
        path: 'newgame',
        name: 'newgame',
        component: NewGame
    },
    {
        path: 'newgamev2',
        name: 'newgamev2',
        component: NewGameV2
    },
    {
        path: 'useraccount',
        name: 'useraccount',
        component: UserAccount
    },
    {
        path: 'scoreviewer',
        name: 'scoreviewer',
        component: ScoreViewer
    },
    {
        path: 'stats',
        name: 'stats',
        component: Stats
    },
    {
        path: 'admin',
        name: 'adminMain',
        component: AdminMain
    },
    {
        path: 'admin/users',
        name: 'adminUsers',
        component: AdminUsers
    },
    {
        path: 'admin/tables',
        name: 'adminTables',
        component: AdminTables
    },
    {
        path: 'clubmanage',
        name: 'clubManage',
        component: ClubManagement
    },
*/         
    {
        path: 'register',
        name: 'register',
        component: Register
    },
    {
        path: 'purchasing',
        name: 'purchasing',
        component: ClubPurchasing
    },    
    {
        path: 'payment-success',
        name: 'paymentSuccess',
        component: PaymentSuccess
    },
    {
        path: 'payment-cancel',
        name: 'paymentCancel',
        component: PaymentCancel
    },
    {
        path: 'my-account',
        name: 'myAccount',
        component: MyAccount
    },
    {
        path: 'dashboard',
        name: 'dashboard',
        component: MyAccount
    },    
    {
        path: 'pixellot-admin',
        name: 'adminMain',
        component: AdminMain
    },

];

const routes = [{
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: subRroutes
  }/*,
  {
    path: '*',
    redirect() {
      return Trans.defaultLocale;
    }
  }*/
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
    var securityCheckNeeded = ["my-account", "dashboard"];

    var isLogged = store.getters.isLoggedIn;
    console.log(" ------- isLogged ------- ");
    console.log(isLogged);
    console.log(" ------- isLogged ------- ");
    if (to.params.hasOwnProperty("locale")) {
        if (Object.keys(i18n.numberFormats).includes(to.params.locale)) {
            var needCheck = false;
            /*
            for (var i = 0; i < securityCheckNeeded.length; i++) {
                if (to.path != null && to.path.indexOf(securityCheckNeeded[i]) > -1) {
                    if (!isLogged) {
                        router.push({ name: 'login', params: { locale: Trans.currentLocale }, query: { redirect: to.fullPath } });
                        return;
                    } else {
                        return next();    
                    }
                } else {
                    return next();
                }
            }
            */

            for (var i = 0; i < securityCheckNeeded.length; i++) {
                if (to.path != null && to.path.indexOf(securityCheckNeeded[i]) > -1) {
                    needCheck = true;
                    break;
                }
            }

            if (needCheck) {
                if (!isLogged) {
                    router.push({ name: 'login', params: { locale: Trans.currentLocale }, query: { redirect: to.fullPath } });
                    return;
                } else {
                    return next();    
                }
            } else {
                return next();
            }


        } else {
            if (to.path == `/${to.params.locale}`) {
                router.push({ name: to.params.locale, params: { locale: Trans.currentLocale }, query: to.query });
            } else {
                router.push({ name: "landing", params: { locale: Trans.currentLocale }});
            }
        }
    } else {
        router.push({ name: "landing", params: { locale: Trans.currentLocale }});
    }
/*
    var toName = to.name == null ? "home" : to.name;

    console.log(`Going to ${toName}`);
    router.push({ name: toName, params: { locale: Trans.currentLocale }});
  */  
    
    // //to.params.hasOwnProperty("locale")
    // if (!to.params.hasOwnProperty("locale") || "en") {
    //     var toName = to.name == null ? "home" : to.name;
    //     router.push({ name: toName, params: { locale: Trans.currentLocale }});
    // }
    
        
    // else
    //     return next();

/*
    if (!store.getters.isLoggedIn) {
        var excludeSecurityCheck = ["login", "register", "confirm", "resetpassword", "scoreviewer", "newgamev2", "gamecontroller", "customerregistration"];
        if (to != null) {
            
            for (var i = 0; i < excludeSecurityCheck.length; i++) {
                if (to.name != null && to.name.indexOf(excludeSecurityCheck[i]) > -1) {
                    return next();
                }
            }
            router.push({ name: 'login', params: { locale: Trans.currentLocale }, query: { redirect: to.fullPath } });

        }
        else {
            next("/login");
        }
    }
    else {
        if (to != null) {
            if (to.path.indexOf("admin") > -1 && store.state.loggedInUser.isSuper != 1) {
                return next("/mainmenu");
            }
            else if (to.path.indexOf("clubmanage") > -1 && (store.state.loggedInUser.clubAdmin == undefined || store.state.loggedInUser.clubAdmin == null || store.state.loggedInUser.clubAdmin == "")) {
                return next("/mainmenu");
            }
        }
        console.log("Logged in!");
        
        if (!to.params.hasOwnProperty("locale"))
            router.push({ name: 'mainmenu', params: { locale: Trans.currentLocale }, query: { redirect: to.fullPath } });
        else
            return next();
    }*/
});

export default router
